html,body
{
  height: max-content;
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;

}

.contactus-form input[type="text"],
.contactus-form input[type="date"],
.contactus-form input[type="datetime"],
.contactus-form input[type="number"],
.contactus-form input[type="search"],
.contactus-form input[type="tel"],
.contactus-form input[type="time"],
.contactus-form input[type="url"],
.contactus-form textarea,
.contactus-form select {
    background: rgba(255,255,255,0.1);
    border: none;
    font-size: 16px;
    height: auto;
    margin: 0;
    outline: 0;
    padding: 15px 0px;
    width: 100%;
    background-color: #ebe8ef;
    color: #000000;
    box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
    margin-bottom: 30px;
}

.contactus-form .email {
    background: rgba(255,255,255,0.1);
    border: none;
    font-size: 16px;
    height: auto;
    margin: 0;
    outline: 0;
    padding: 15px 0px;
    width: 100%;
    background-color: #ebe8ef;
    color: #8a97a0;
    box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
    margin-bottom: 30px;
}

.contactus-form input[type="radio"],
.contactus-form input[type="checkbox"] {
    margin: 0 4px 8px 0;
}

.contactus-form select {
    padding: 6px;
    height: 32px;
    border-radius: 2px;
}

.contactus-form .submitButton {
    padding: 19px 39px 18px 39px;
    color: #FFF;
    background-color: rgba(30, 37, 94);
    font-size: 18px;
    text-align: center;
    font-style: normal;
    border-radius: 5px;
    width: 100%;
    border: 1px solid rgba(30, 37, 94);
    border-width: 1px 1px 3px;
    box-shadow: 0 -1px 0 rgba(255,255,255,0.1) inset;
    margin-bottom: 10px;
}

.contactus-form fieldset {
    margin-bottom: 30px;
    border: none;
}

.contactus-form legend {
    font-size: 1.4em;
    margin-top: 10px;
    margin-bottom: 10px;
}

.contactus-form label {
    display: block;
    margin-bottom: 8px;
}

.contactus-form label.light {
    font-weight: 300;
    display: inline;
}

.contactus-form .number {
    background-color: rgba(30, 37, 94);
    color: #fff;
    height: 30px;
    width: 30px;
    display: inline-block;
    font-size: 0.8em;
    margin-right: 4px;
    line-height: 30px;
    text-align: center;
    text-shadow: 0 1px 0 rgba(255,255,255,0.2);
    border-radius: 100%;
}

@media screen and (min-width: 480px) {
    .contactus-form {
        max-width: 480px;
    }
}
