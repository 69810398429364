#ErrorMain{
    display: flex;
    flex-direction: column;
    width: auto;
    height: 800px;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: xx-large;
    font-family: "Poppins";
}

.ErrorSub{
	  display: table-cell;
	  vertical-align: middle;
}

body, html { 
  font-family: 'Poppins', sans-serif;
}

.ErrorSub h1{
	  font-size: 50px;
	  display: inline-block;
	  padding-right: 12px;
	  animation: type .5s alternate infinite;
}

@keyframes type{
	  from{box-shadow: inset -3px 0px 0px #888;}
	  to{box-shadow: inset -3px 0px 0px transparent;}
}
.user-portal-container {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

.user-portal-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Pushes content up and footer down */
  padding: 20px;
}