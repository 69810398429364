/* Ensure the page takes up full height */
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* Push footer to the bottom */
.main-content {
  flex: 1;
}

/* Footer Styles */
.site-footer {
  background-color: rgba(30, 37, 94);
  padding: 20px 20px 20px 30px;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  flex-shrink: 0;
  z-index: "1201",

}

.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}

.site-footer a {
  color: #ffffff;
}

.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links {
  padding-left: 0;
  list-style: none;
}

.footer-links li {
  display: block;
}

.footer-links a {
  color: #ffffff;
}

.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}

.site-footer .social-icons {
  text-align: right;
}

.copyright-text {
  margin: 0;
  text-align: center;
}

@media (max-width: 991px) {
  .site-footer .col-md-6,
  .site-footer .col-md-3 {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
